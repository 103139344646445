<template>
  <div>
    <!--    步骤条区域-->
    <org-apply-steps :activeStep="stepThree"></org-apply-steps>
    <div class="submit-success">
      <div class="content">
        <img src="~@/assets/pc/images/organization/submit.png" alt="" class="content-img">
        <div class="middle">您的资料已提交成功</div>
        <div class="bottom">平台正在审核中，预计3-5个工作日内处理完成</div>
      </div>
    </div>
  </div>
</template>

<script>
import orgApplySteps from './orgApplySteps';
export default {
  name: 'orgSubmitSuccess',
  components: {
    orgApplySteps
  },
  data () {
    return {
      stepThree: 2
    };
  },
  created () {
    if (this.stepThree === 3) {
      this.stepThree = 3;
      this.goToNext();
    }
  },
  methods: {
    goToNext () {
      this.stepTwo = 3;
      this.$router.replace('/orgsubmitsuccess');
    }
  }
};
</script>

<style scoped>
.submit-success {
  width: 100%;
  padding-top: 108px;
  padding-left: 377px;
}
.content {
  position: relative;
  width: 337px;
  height: 160px;
}
.content-img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.content .middle {
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  font-weight: bold;
}
.content .bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 16px;
  color: #8D8D8D;
}
</style>
